<template>
  <component :is="isPage?'div':'BSidebar'" id="supplier-view" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
    <template v-slot:header>
      <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
        <div v-if="supplierData" class="flex gap-3 align-items-center text-primary">
          <fa-icon :icon="['fad', $t('member.supplier.icon')]" size="2x"/>
          <div class="flex flex-column">
            <span class="text-capitalize">{{$t('member.supplier._')}}</span>
            <span class="m-0 h5">{{supplierData.name}}</span>
          </div>
          <q-btn flat padding="5px" :to="{name: 'member-supplier-view', params:{supplierId: supplierData._id}}"><fa-icon icon="expand"/></q-btn>
        </div>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
      </div>
    </template>
      
    <div v-if="supplierData" class="bg-white">
      <Carousel :files="supplierData.files" />
      <div class="shadow-top-sm p-2 flex flex-column gap-2">
        <!-- Corp -->
        <div class="d-flex gap-1 align-items-center">
          <span class="h4 mb-0">{{supplierData.corpName}}</span>
          <q-chip v-if="supplierData.uen" dense class="px-50">{{supplierData.uen}}</q-chip>
        </div>
        <!-- User -->
        <div class="flex-even d-flex gap-2">
          <fa-icon :icon="['fad','user']" fixed-width class="mt-50 text-primary text-size-150"/>
          <div>
            <label class="text-primary d-block m-0 text-capitalize">{{$t('form.name')}}</label>
            <div v-if="supplierData.name" class="text-capitalize">{{supplierData.name}}</div>
            <div v-if="supplierData.phone"><q-chip dense :label="supplierData.country"/> {{supplierData.phone}}</div>
            <div v-if="supplierData.email">{{supplierData.email}}</div>
            <div v-if="supplierData.url">{{supplierData.url}}</div>
          </div>
        </div>
        <!-- Tags -->
        <div class="flex-even flex-size-4 flex gap-2">
          <fa-icon :icon="['fad','tags']" fixed-width class="mt-50 text-primary text-size-150"/>
          <div>
            <label class="text-primary d-block text-capitalize">{{$t('form.tags')}}</label>
            <span class="d-flex flex-wrap gap-1">
              <q-chip v-for="(tag, index) in supplierData.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
              <q-btn flat dense @click.stop="updateTags"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
            </span>
          </div>
        </div>
      </div>
      <!-- 地址 -->
      <h5 v-if="supplierData.addresses && supplierData.addresses.length>0" class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
        <span class="flex gap-2 align-items-center text-capitalize">
          <fa-icon :icon="['fad','map']" fixed-width />
          {{$t('action.address')}}
        </span>
      </h5>
      <div v-for="address in supplierData.addresses" :key="address._id" class="rounded-lg shadow-sm p-1 mx-2 my-1 bg-light-primary border-primary d-flex flex-column flex-sm-row gap-2">
        <div>
          <div class="d-flex justify-content-between align-items-center gap-2">
            <h5 class="m-0">{{address.name}}</h5>
          </div>
          <span>{{address.country}} {{address.phone}}</span>
        </div>
        <q-separator vertical class="d-none d-sm-inline" />
        <q-separator class="d-sm-none" />
        <div>
          <div>{{address.unit}}</div>
          <div class="text-wrap">{{address.address}}</div>
          <div>{{address.city}} {{address.zip}}</div>
        </div>
      </div>
    </div>
    
    <FieldDialog ref="fieldDialogRef" />
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </component>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { BSidebar } from 'bootstrap-vue'
import { price, i18n } from '@core/utils/filter'
import { Dialog } from '@core/utils/other'
import useSupplier from './useSupplier'
import Carousel from '@core/components/Carousel'
import FileGallery from '@core/components/file-gallery/FileGallery'
import FieldDialog from '@core/components/FieldDialog'

export default {
  name: 'supplier-view',
  components: {
    Carousel,
    BSidebar,
    FileGallery,
    FieldDialog
  },
  props:{
    isPage: Boolean
  },
  filters:{
    price
  },
  setup(props) {
    const { route, router } = useRouter()
    const {
      loading,
      supplierData,
      fetchSupplier,
    } = useSupplier()
    

    // Page view
    if (props.isPage) {
      if (route.value.params.supplierId) {
        loading.value = true
        fetchSupplier(route.value.params.supplierId, ()=>loading.value = false)
      } else {
        router.go(-1)
      }
    }

    // Sidebar view
    const sidebarWidth = ref('600px')
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchSupplier(id, ()=> {
          loading.value = false
          closeAction = () => {
            res(isUpdated)
            isSidebarActive.value = false
          }
        })
      })
    }

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async () =>{
      const newTags = await fieldDialogRef.value.openDialog({data:supplierData.value.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      loading.value = true
      store.dispatch('supplier/updateTags', {supplierId: supplierData.value._id, tags:newTags}).then(()=> fetchSupplier(supplierData.value._id, ()=>{
        isUpdated = true
        loading.value = false
      })).catch(e=>{
        loading.value = false
        Dialog(false).fire({
          icon: 'error',
          title: e.message
        })
      })
    }

    return {
      loading,
      sidebarWidth,
      supplierData,
      fetchSupplier,

      // Sidebar
      isSidebarActive,
      open,
      close,

      // Tags
      fieldDialogRef,
      updateTags,
    }
  }
}
</script>

<style lang="scss">
#supplier-view {
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }
}
</style>